.consent-wrapper .sub-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #363f72;
}
.btn-sec {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 15px;
}
.btn-sec > div:not(:last-child) {
    margin-right: 15px;
}
.table-wrapper.consent-list td:last-child,
.table-wrapper.consent-list th:last-child {
    width: 200px;
}
.table-wrapper.consent-list td:nth-of-type(1),
.table-wrapper.consent-list th:nth-of-type(1) {
    width: 70px;
}

.consent-wrapper .input-row label {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #363f72;
    margin-bottom: 10px;
    display: inline-block;
}
.consent-wrapper .rsw-editor {
    height: 400px;
    overflow-y: auto;
}
.rsw-toolbar button[title="Link"] {
    display: none !important;
}
.rsw-toolbar button[title="Clear formatting"] {
    display: none !important;
}
