.popup-field {
    margin-bottom: 15px;
}
.popup-field:last-child {
    margin-bottom: 0;
}
.popup-field label {
    margin-bottom: 5px;
    display: inline-block;
    font-size: 14px;
    color: #344054;
}
.popup-field .MuiOutlinedInput-input.MuiInputBase-input {
    font-size: 14px;
    color: #707070;
}

.popup-field .MuiOutlinedInput-input.MuiInputBase-input::placeholder {
    font-size: 14px;
    color: #707070;
}
.dialog-btn-wrap .MuiDialogActions-root {
    justify-content: center;
}
.dialog-btn-wrap .MuiDialogActions-root button {
    width: 235px;
    height: 42px;
}
