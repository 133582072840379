.table-wrapper.village-detail-list thead th:first-child {
    width: 70px;
}
.table-wrapper.village-detail-list td:nth-of-type(1),
.table-wrapper.village-detail-list th:nth-of-type(1) {
    text-align: center;
}
.table-wrapper.village-detail-list td:nth-of-type(2),
.table-wrapper.village-detail-list th:nth-of-type(2) {
    width: 250px;
}
.table-wrapper.village-detail-list td:last-child,
.table-wrapper.village-detail-list th:last-child {
    width: 150px;
    text-align: center;
}
.table-wrapper.village-detail-list .action-wrap {
    justify-content: center;
}
