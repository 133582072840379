.pagination-wrap {
    padding: 25px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.pagination {
    display: flex;
    align-items: center;
}
.pagination-sec .left-arrow,
.pagination-sec .right-arrow {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.enable .MuiButtonBase-root {
    color: #000 !important;
}
.disable .MuiButtonBase-root {
    color: rgba(0, 0, 0, 0.54) !important;
}
.pagination-sec .MuiSvgIcon-root {
    font-size: 18px;
}
