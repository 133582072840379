.account-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #363f72;
}
.account-inner {
    max-height: calc(100vh - 20px);
    max-width: 90%;
    width: 460px;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 4px 30px 0px #363f72;
    overflow: hidden;
}
.login-section .logo {
    padding-bottom: 12px;
}
.login-section .logo figure {
    width: 79px;
    margin: 0 auto;
}

.page-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    color: #363f72;
    text-align: center;
}
.page-title.bottom-spacing {
    margin-bottom: 15px;
}
.page-description {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.2px;
    text-align: center;
    color: #707070;
}
.form-wrapper {
    width: 100%;
    padding: 10px 0 0;
}
.account-inner label {
    margin-bottom: 5px;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #344054;
}
.input-row {
    width: 100%;
    margin-bottom: 15px;
    position: relative;
}
.eye-icon {
    position: absolute;
    right: 15px;
    top: 66%;
    transform: translateY(-50%);
}
.password-row {
    width: 100%;
    position: relative;
}
.password-row.top-spacing {
    margin-top: 15px;
}
.forgot-link {
    text-align: right;
    padding: 5px 0 0;
}
.forgot-link a {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #363f72;
    text-decoration: none;
}
.forgot-link a:hover {
    text-decoration: underline;
}
.remember-me {
    padding: 5px 0;
    display: flex;
    align-items: center;
}
.remember-me p {
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: #344054;
}
.remember-me .MuiSvgIcon-root,
.password-row .MuiSvgIcon-root {
    color: #344054;
}
.btn-wrap button {
    text-transform: capitalize;
    width: 100%;
}
.btn-wrap button a {
    text-decoration: none;
    color: #fff;
}
.btn-wrap .back-login-btn {
    padding-top: 20px;
}
.btn-wrap button {
    height: 60px;
}

.error-box {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.error-box .error-description {
    margin-left: 9px;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.0048px;
    color: #ff0000;
}
.right-arrow-section {
    position: absolute;
    right: 20px;
    top: 20px;
}
.btn-wrap.reset-btn {
    padding-top: 25px;
}
.remember-me .MuiCheckbox-root {
    margin-left: -10px;
}
.back-login-btn {
    text-align: center;
}
.back-login-btn a {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #363f72;
    text-decoration: none;
}
.back-login-btn a:hover {
    text-decoration: underline;
}
@media (max-width: 1024px) {
    .account-inner {
        width: calc(100% - 350px);
    }
    .login-section {
        width: 100%;
        padding: 5%;
    }
    .page-title {
        margin-bottom: 20px;
        font-size: 25px;
    }
    .page-description {
        font-size: 20px;
    }
    .form-wrapper {
        padding: 15px 0 0;
    }
    .input-row {
        margin-bottom: 15px;
    }
    .account-inner label {
        font-size: 16px;
    }
    .remember-me {
        padding: 15px 0;
    }
    .form-wrapper.top-spacing {
        padding-top: 10px;
    }
    .password-row.top-spacing {
        margin-top: 15px;
    }
    .btn-wrap.reset-btn {
        padding-top: 20px;
    }
    .btn-wrap button {
        height: 50px;
    }
}

@media (max-width: 767px) {
    .account-inner {
        width: 100%;
    }
    .login-section {
        padding: 5%;
    }
    .page-title {
        margin-bottom: 15px;
        font-size: 22px;
    }
    .page-description {
        font-size: 18px;
    }
    .form-wrapper {
        padding: 10px 0 0;
    }
    .input-row {
        margin-bottom: 15px;
    }
    .account-inner label {
        font-size: 14px;
    }
    .forgot-link a {
        font-size: 14px;
    }
    .remember-me {
        padding: 10px 0;
    }
    .remember-me p {
        font-size: 16px;
    }
    .btn-wrap .back-login-btn {
        padding-top: 15px;
    }
    .form-wrapper.top-spacing {
        padding-top: 0;
    }
    .password-row.top-spacing {
        margin-top: 15px;
    }
    .btn-wrap.reset-btn {
        padding-top: 15px;
    }
    .btn-wrap button {
        height: 45px;
    }
}
