.pageWrapper {
    display: flex;
    width: 100%;
}

.main-section-inner {
    min-height: calc(100vh - 100px);
    padding: 15px;
    background: #fff;
}
.main-section {
    min-height: calc(100vh - 70px);
    top: 70px;
    width: calc(100% - 265px);
    bottom: 0;
    position: fixed;
    left: 265px;
    overflow-x: auto;
    overflow-y: auto;
    padding: 15px;
    background: #f2f2f2;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
}
.main-section-width {
    width: 100%;
    left: 0;
}
