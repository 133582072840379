.content-tab-wrap .sub-title {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #363f72;
}
.content-tabs {
    background-color: rgba(54, 63, 114, 0.06);
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #363f72;
    margin-bottom: 15px;
}
.content-tabs .tab1{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-tabs .tab1 .edittext{
  text-decoration: underline;
  font-size: 15px;
  color: #363f72;
}
.content-tab-wrap a {
    text-decoration: none;
}
