.notification-title {
    padding-bottom: 15px;
}
.notification-title .sub-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    color: #363f72;
}
.notification-add-wrap {
    width: 650px;
}
.notification-add-wrap .form-label {
    margin-bottom: 5px;
    display: inline-block;
    font-size: 14px;
    color: #344054;
}
.notification-add-wrap .input-field {
    margin-bottom: 15px;
}
.checkbox-wrap {
    display: flex;
    margin-left: -10px;
}
.checkbox-wrap .checkbox-content {
    margin-right: 30px;
    display: flex;
    align-items: center;
}
.checkbox-wrap .checkbox-content:last-child {
    margin-right: 0;
}
.checkbox-content p {
    font-size: 17px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.00035px;
    color: #363f72;
}
.select-user-wrap {
    border: 1px solid #363f72;
    border-radius: 5px;
    margin-bottom: 15px;
}
.select-user-head {
    padding: 15px;
    border-bottom: 1px solid #363f72;
}
.select-user-head h3 {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    color: #363f72;
}
.input-field button {
    height: auto !important;
}
.input-field button .MuiSvgIcon-root {
    color: #707070 !important;
}
.btn-section {
    padding: 10px 0;
}
.btn-section button:not(:last-child) {
    margin-right: 15px;
}
.btn-section > button {
    width: 150px;
}

/* multiselect-styling */
.MuiButtonBase-root.MuiChip-root {
    background-color: #008ab4;
}
.MuiButtonBase-root.MuiChip-root span {
    color: #fff;
}
.MuiButtonBase-root.MuiChip-root .MuiChip-deleteIcon {
    color: #fff;
}
.slect-user-body .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
    font-size: 14px;
    padding: 14px !important;
    font-family: "soleilregular";
}
