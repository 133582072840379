.sidebar {
    width: 265px;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    background: #ffffff;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    border-right: 1px solid #667085;
}
.sidebarInner {
    height: 100%;
    overflow-y: auto;
}
.sidebar-header {
    height: 115px;
    padding: 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-title h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    color: #363f72;
}
.sidebar-main {
    height: calc(100vh - 115px);
    overflow-y: auto;
}
.sidebar-menu a {
    padding: 10px 15px;
    height: 44px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    color: #27303f;
    transition: 0.3s ease-in;
}
.sidebar-menu .sub-menu {
    background: #edf7fa;
    /* display: none; */
}
.sidebar-menu .sub-menu a {
    padding: 10px 15px 10px 45px;
}
.sidebar-menu a:hover {
    background: rgba(0, 0, 0, 0.04);
    font-weight: 600;
    color: #27303f;
}
.sidebar-menu i.icon {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background-size: 100% 100% !important;
    transition: 0.3s ease-in;
}
.sidebar-menu a .dashboard {
    background: url("../images/sidebar/home.svg") center center no-repeat;
}
.sidebar-menu a:hover .dashboard {
    background: url("../images/sidebar/home.svg") center center no-repeat;
}
.sidebar-menu a.active:hover .dashboard {
    background: url("../images/sidebar/home.svg") center center no-repeat;
}
.sidebar-menu a.active .dashboard {
    background: url("../images/sidebar/home-1.svg") center center no-repeat;
}
.sidebar-menu a .user {
    background: url("../images/sidebar/user.svg") center center no-repeat;
}
.sidebar-menu a:hover .user {
    background: url("../images/sidebar/user.svg") center center no-repeat;
}
.sidebar-menu a.active:hover .user {
    background: url("../images/sidebar/user.svg") center center no-repeat;
}
.sidebar-menu a.active .user {
    background: url("../images/sidebar/user-1.svg") center center no-repeat;
}
.sidebar-menu a .village {
    background: url("../images/sidebar/village.svg") center center no-repeat;
}
.sidebar-menu a:hover .village {
    background: url("../images/sidebar/village.svg") center center no-repeat;
}
.sidebar-menu a.active:hover .village {
    background: url("../images/sidebar/village.svg") center center no-repeat;
}
.sidebar-menu a.active .village {
    background: url("../images/sidebar/village-1.svg") center center no-repeat;
}

.sidebar-menu a .notification {
    background: url("../images/sidebar/notification.svg") center center no-repeat;
}
.sidebar-menu a:hover .notification {
    background: url("../images/sidebar/notification.svg") center center no-repeat;
}
.sidebar-menu a.active:hover .notification {
    background: url("../images/sidebar/notification.svg") center center no-repeat;
}
.sidebar-menu a.active .notification {
    background: url("../images/sidebar/notification-1.svg") center center no-repeat;
}

.sidebar-menu a .consent {
    background: url("../images/sidebar/consent.svg") center center no-repeat;
}
.sidebar-menu a:hover .consent {
    background: url("../images/sidebar/consent.svg") center center no-repeat;
}
.sidebar-menu a.active:hover .consent {
    background: url("../images/sidebar/consent.svg") center center no-repeat;
}
.sidebar-menu a.active .consent {
    background: url("../images/sidebar/consent-1.svg") center center no-repeat;
}

.sidebar-menu a .content {
    background: url("../images/sidebar/content_management.svg") center center no-repeat;
}
.sidebar-menu a .content{
    background: url("../images/sidebar/content_management.svg") center center no-repeat;
}
.sidebar-menu a:hover .content {
    background: url("../images/sidebar/content_management.svg") center center no-repeat;
}
.sidebar-menu a.active:hover .content {
    background: url("../images/sidebar/content_management.svg") center center no-repeat;
}
.sidebar-menu a.active .content  {
    background: url("../images/sidebar/content-1.svg") center center no-repeat;
}
.sidebar-menu a .privacy-policy {
    background: url("../images/sidebar/privacy.svg") center center no-repeat;
}
.sidebar-menu a .terms-conditions {
    background: url("../images/sidebar/terms.svg") center center no-repeat;
}
.sidebar-menu a .faq {
    background: url("../images/sidebar/faq.svg") center center no-repeat;
}
.sidebar-menu a .notification {
    background: url("../images/sidebar/notification.svg") center center no-repeat;
}
.sidebar-menu .sub-menu {
    list-style: none;
}
.sidebar-menu .parent-menu a.arrow {
    position: relative;
}
.sidebar-menu .parent-menu a.arrow:before {
    width: 7px;
    height: 7px;
    border-bottom: 2px solid #2461d6;
    border-right: 2px solid #2461d6;
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    right: 16px;
    top: 52%;
    transition: 0.3s ease-in;
}
.sidebar-menu .parent-menu.down-arrow a.arrow:before {
    margin-top: -6px;
    transform: rotate(45deg);
}
.sidebar-menu .parent-menu.up-arrow a.arrow:before {
    margin-top: -2px;
    transform: rotate(225deg);
    top: 50%;
    border-color: #27303f;
}
.sidebar-menu .parent-menu a.arrow:hover:before {
    border-color: #fff;
}
.sidebar-menu ul.sub-menu li a:hover {
    background-color: #363f72;
    font-weight: 600;
    color: #27303f;
}

a.active {
    background-color: #363f72;
    font-weight: 600;
    color: #fff;
}

.sidebar-width {
    width: 0;
}
