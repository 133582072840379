.table-wrapper.user-list thead th:first-child {
    width: 70px;
    text-align: center;
}
.table-wrapper.user-list td:first-child,
.table-wrapper.user-list th:first-child {
    width: 70px;
    text-align: center;
}
.table-wrapper.user-list td:nth-of-type(2),
.table-wrapper.user-list th:nth-of-type(2) {
    width: 250px;
}
.table-wrapper.user-list td:nth-of-type(3),
.table-wrapper.user-list th:nth-of-type(3) {
    width: 250px;
}
.table-wrapper.user-list td:last-child,
.table-wrapper.user-list th:last-child {
    width: 150px;
}
.sort-outer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.user-info-sec {
    display: flex;
    align-items: center;
}
.user-info-sec .user-image {
    margin-right: 10px;
}
.user-info-sec .user-image figure {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    overflow: hidden;
}
.user-info-sec .user-image img {
    width: 100%;
    height: 100%;
}
.action-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.table-btn .d-none {
    display: none;
}
.table-btn .d-block {
    display: block;
}
