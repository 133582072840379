.table-wrapper.vil-list thead th:first-child {
    width: 70px;
    text-align: center;
}
.table-wrapper.vil-list td:first-child,
.table-wrapper.vil-list th:first-child {
    width: 70px;
    text-align: center;
}
.table-wrapper.vil-list td:nth-of-type(2),
.table-wrapper.vil-list th:nth-of-type(2) {
    width: 250px;
}
.table-wrapper.vil-list td:nth-of-type(3),
.table-wrapper.vil-list th:nth-of-type(3) {
    width: 250px;
}
.table-wrapper.vil-list td:nth-of-type(4),
.table-wrapper.vil-list th:nth-of-type(4) {
    text-align: center;
}
.table-wrapper.vil-list thead th:nth-of-type(5) {
    text-align: center;
}
.table-wrapper.vil-list thead th:nth-of-type(5) .sort-outer {
    justify-content: center;
}
.table-wrapper.vil-list td:nth-of-type(5),
.table-wrapper.vil-list th:nth-of-type(5) {
    text-align: center;
}
.table-wrapper.vil-list td:last-child,
.table-wrapper.vil-list th:last-child {
    width: 150px;
}
.action-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
