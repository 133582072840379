.profile-detail-inner {
  padding: 15px;
  border: 1px solid #667085;
  border-radius: 5px;
  background-color: #fff;
  margin-bottom: 15px;
}
.user-profile-wrap .sub-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  color: #363f72;
}
.user-profile-info {
  display: flex;
}
.user-upload-file .close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}
.user-upload-file .close-icon .MuiSvgIcon-root {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  color: red;
  transition: all 0.3s ease-in;
  cursor: pointer;
}
.user-upload-file {
  width: 150px;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  background-color: #f2f2f2;
  border: 1px dashed #00536c;
}
.user-upload-file input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}
.user-upload-file .upload-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.user-upload-file .upload-icon figure {
  width: 50px;
  height: 50px;
  margin: 0 auto 10px;
}
.user-upload-file .upload-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user-upload-file p {
  margin-bottom: 15px;
  font-size: 14px;
  white-space: nowrap;
}
.user-profile-info .col-right {
  padding: 0 0 0 35px;
  width: calc(100% - 150px);
}
.register-id {
  padding-top: 25px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.user-profile-wrap .breadcrumb-right {
  display: flex;
}
.user-profile-wrap .breadcrumb-right .edit-btn button {
  color: #fff !important;
}
.user-profile-wrap .breadcrumb-right > div:not(:last-child) {
  margin-right: 15px;
}

.user-info label {
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: #363f72;
  display: inline-block;
  width: 100%;
}
.user-info p {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #667085;
}
.user-info {
  margin-bottom: 12px;
}
.user-info:last-child {
  margin-bottom: 0;
}
.breadcrumb-left .back-arrow .MuiSvgIcon-root {
  margin-right: 0px !important;
  vertical-align: middle;
  color: #363f72;
  font-size: 26px;
  cursor: pointer;
}
.user-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.user-col {
  max-width: 25%;
  flex: 0 0 25%;
  padding: 0 8px;
  margin-bottom: 15px;
}
.user-col-100 {
  max-width: 100%;
  flex: 0 0 100%;
  padding: 0 8px;
}
.profile-header {
  background-color: rgba(54, 63, 114, 0.06);
  padding: 15px;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #363f72;
}
.profile-detail-inner.p-0 {
  padding: 0;
}
.village-details {
  padding: 15px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.table-heading {
  padding-bottom: 10px;
  border-bottom: 1px solid #363f72;
  margin-bottom: 15px;
}
.table-wrapper.village-list thead th:first-child {
  width: 70px;
}
.table-wrapper.village-list td:nth-of-type(1),
.table-wrapper.village-list th:nth-of-type(1) {
  text-align: center;
}
.table-wrapper.village-list td:nth-of-type(4),
.table-wrapper.village-list th:nth-of-type(4) {
  text-align: center;
}
.table-wrapper.village-list td:nth-of-type(3),
.table-wrapper.village-list th:nth-of-type(3) {
  text-align: center;
}
.table-wrapper.village-list .action-wrap {
  justify-content: center;
}
.table-wrapper.village-list td:last-child,
.table-wrapper.village-list th:last-child {
  width: 150px;
  text-align: center;
}

/* new css started here */

.users-heading {
  margin: 30px 0 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 7px;
}

.users-heading .border {
  height: 1px;
  flex: 1;
  background-color: #00000022;
}

.user-joined {
  display: flex;
  justify-content: space-between;
}

.alldata .MuiPaper-root {
  /* border-radius: none !important; */
  border: none !important;
}
.alldata .consent {
  cursor: pointer;
  color: #363f72;
}

.modalTest {
  background-color: #c00;
}

.closeButon {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.subscription-paid {
  display: grid;
  /* max-width: 50%; */
  margin: 20px auto;
  gap: 8px;
  grid-template-columns: 200px 200px 200px;
}

.subscription-paid .tile {
  background-color: #363f72;
  border-radius: 10px;
  padding: 10px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.subscription-paid .tile h2 {
  color: #f2f2f2;

  font-size: 15px;
}
.subscription-paid .tile span {
  color: #f2f2f2;
}

.custom-modal {
  max-height: 70vh;
  overflow-y: scroll;
}
